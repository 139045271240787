.ce-block__content,
.ce-toolbar__content {
    max-width: unset;
}

.cdx-nested-list--ordered {
  counter-reset: item 0;
}

.cdx-nested-list--ordered>.cdx-nested-list__item:before {
  content: counters(item,".") ". ";
  counter-increment: item;
}

div[contentEditable=true][data-placeholder]:empty:before {
    opacity: 1;
}
.codex-editor{
  z-index: auto !important;
}
.ce-popover{
  --width: 350px;
}
.ce-toolbar--opened{
  z-index: 4;
}

.custom-col-6 {
    width: 50%;
    float: left;
}

.ce-block:not(.custom-col-6) {
    clear: both;
}

button.cdx-settings-button--active {
    background-color: #388ae5;
    color:#fff;
}

button.cdx-settings-button--active:hover{
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

button.cdx-settings-button--active svg {
    fill:#fff;
}
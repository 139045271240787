#thumbnail {
    display: none; /* Hide the actual input */
}

label[for="thumbnail"] {
    cursor: pointer; /* Change cursor to indicate clickable area */
    /* Add any additional styling you need for the label here */
}

label[for="thumbnail"] svg {
    /* Style for your SVG icon */
    fill: #333; /* Example: Change the color of the icon */
    /* Add any other SVG styling you need */
}

input#dateTime{
    position: relative;
}

input[type="date" i]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-size: 14px;
    background-position: right 15px center;
}

.table thead tr.post-header th{
    border-bottom: 0;
}

tr.post-row td > span{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: normal;
}

.dropdown.edit-dropdown .dropdown-menu{
    min-width: 110px;
}

.dropdown.edit-dropdown .dropdown-menu li span{
    cursor: pointer;
}
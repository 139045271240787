html body {
    background: #fff;
}

nav#sidebarMenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
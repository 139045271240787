.sidebar {
    padding-left: 15px;
}

.post {
    width: calc(50% - 20px);
    float: left;
    border: 1px solid #f0f0f0;
    padding: 15px;
    margin: 0 10px 15px;
}

@media(max-width: 650px){
    .post{
        width: 100%;
        margin: 0 0 15px;
    }
}

.row-post-masonry .post {
    padding-bottom: 25px;
    margin-bottom: 25px;
}

.row-post-thumbnail .post {
    border: 0;
    padding-bottom: 25px;
    margin-bottom: 25px;
}

/* .post:last-of-type {
    border: 0;
} */

.post-preview {
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: .1875rem;
    transition: all .3s ease-in-out;
    margin: 0 0 35px;
}

.post-preview img {
    border-radius: 0.1875rem;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.row-post-thumbnail .post-preview {
    margin: 0;
}

.post-preview:hover {
    box-shadow: 0 4px 16px rgba(90, 90, 90, .05);
    transform: translateY(-5px);
}

.row-post-masonry .post-wrapper {
    padding: 0 5px;
}

.post-header {
    margin: 0 0 30px;
}

.post-header .post-title {
    font-weight: 500;
    font-size: 1.5625rem;
    margin-bottom: 8px;
}

@media(max-width: 650px){
    .post-header .post-title{
        font-size: 1.25rem;
    }
}

.post-content{
     margin-bottom: 15px;
}
.post-content p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: normal;
}

.row-post-masonry .post-header .post-title {
    font-size: 1rem;
    margin-bottom: 14px;
}

.row-post-thumbnail .post-header .post-title {
    font-size: 1.125rem;
    margin-bottom: 6px;
}

.row-post-masonry .post-header {
    margin: 0 0 10px;
}

.row-post-thumbnail .post-header {
    margin: 0 0 16px;
}

.post-meta {
    list-style: none;
    font-size: .8125rem;
    color: #a3a6a8;
    padding: 0;
    margin: 0;
}

.post-meta > li {
    position: relative;
    display: inline-block;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
}

.post-meta > li > a {
    color: #a3a6a8;
}

.post-meta > li > a:hover {
    color: #222;
}

.post-meta > li:not(:last-child):after {
    position: absolute;
    content: '/';
    right: -5px;
    top: 0;
}

.post-content blockquote {
    border-left: 2px solid #eaeaea;
    padding: 10px 0 10px 40px;
    margin: 40px 0;
    transition: all .3s ease-in-out;
}

.post-content blockquote > p{
    font-size: 1.25rem;
}

.post-content blockquote:hover {
    border-color: var(--bs-primary);
}

.post-content ul,
.post-content ol {
    margin-left: 40px;
}

.post-content ul {
    list-style-type: disc;
}

.post-content ol {
    list-style-type: decimal;
}

@media (max-width: 991.98px) {
    .sidebar {
        padding-left: 0;
        margin-top: 80px;
    }
}

@media (max-width: 767.98px) {
    .row-post-thumbnail .post-wrapper {
        padding: 35px 0 0;
    }
}

.post-date {
    font-size: .6875rem;
    color: #a3a6a8;
    display: block;
    margin: 0;
}

.tag-cloud > a,
.post-tags > a {
    background: #f4f4f4;
    border-radius: .1875rem;
    position: relative;
    display: inline-block;
    padding: 1px 15px;
    margin: 5px 8px 5px 0;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    line-height: 24px;
    font-size: .5rem !important;
    color: #222;
    cursor: pointer;
}

.tag-cloud > a.active, .post-tags > a.active {
    color: #fafafa !important;
    background: var(--bs-primary);
    font-weight: bold;
}

.tag-cloud > a:hover,
.post-tags > a:hover {
    background: var(--bs-primary);
    color: #fff !important;
    opacity: 1;
}

.tag-cloud > a.active:hover, 
.post-tags > a.active:hover {
    color: #fafafa;
}
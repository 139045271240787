.contact{
    width: 600px !important;
    background-color: #091433 !important;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #fafafa !important;
}

.contact h2 {
    color: #fafafa;
    font-size: 50px;
    line-height: 50px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

@media(max-width: 768px){
    .contact h2 {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

.contact .close {
    color: #fafafa;
    width: 20px;
    position: absolute;
    right: 31px;
    top: 34px;
}

.contact-text {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    color: #8c8c8c;
}